<template>
    <!-- 基本信息 -->
    <div>
        <el-container>
            <el-header  style="height:85px">
                <topHead :detalis='detalis'/>
            </el-header>
            <el-main >
               <!-- 内容 -->
             <div class="card">
                   <reject  />
                   <!-- 成功 -->
                   <fail  />
             </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import topHead from "./components/topHead.vue";
import reject from "./components/reject.vue";
import fail from "./components/fail.vue";
export default {
  data() {
    return {
        detalis:null,
        flag:false,
        process_id:null
    };
  },
  components: {
    topHead,reject,
    fail
  },
  created() {
    this.getMgt()
    this.query()
    console.log(this.$route.query)
    if(this.$route.query.process_id == 0){
        this.process_id = 0
    }
    if(this.$route.query.process_id == 1){
        this.process_id = 1
    }
  },
  methods: {
    query(){
      this.$axios.get('/crm/shortlist/shortlist_detail',{code:this.$route.query.code}).then(res=>{
        if(res.code == 200){
          
        }
      })
    },


     // 获取设置
    getMgt(id){
      this.$axios.get('/setting/shortlisted',{company_id:this.$route.query.company_id}).then(res=>{
        if(res.code ==200){
          this.detalis = res.data.value
          console.log(this.detalis)
          this.flag = true
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.el-container {
  width: 100%;

  .el-header {
    // background-color: #b3c0d1;
    color: #333;
    text-align: center;
    line-height: 85px;
    // width: 1200px;
    margin: auto;
    background-color: rgba(255, 255, 255, 100);
    box-shadow: 0px 2px 6px 4px rgba(0, 0, 0, 0.1);
   
  }
  .el-main {
    margin: auto;
    text-align: left;
    .card{
        margin:30px 25px;
        background-color:#fff;
        min-height: 800px;
        padding: 30px 70px;
        box-sizing: border-box;
    }
  }
}
</style>